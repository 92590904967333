<template>
  <div class="home-container">
    <Header :personalInfo="true"></Header>
    <div class="main-container">
      <!--        侧边菜单栏 -->
      <div class="aside-layout">
        <div class="menu-title-layout">
          <div class="divider-1"></div>
          <div class="menu-title-content-style">本体浏览</div>
        </div>
        <div class="tree-container">
<!--          <el-scrollbar class="my-scl">-->
            <el-tree
                :data="menu_data"
                :props="defaultProps"
                @node-click="handleNodeClick"
                highlight-current
                class="tree">
            </el-tree>
<!--          </el-scrollbar>-->
        </div>
      </div>
      <!--        人体浏览区-->
      <el-main class="main-layout">
        <div class="body-preview-container">
          <div class="body-title-layout">
            <div class="divider-3"></div>
            <div class="body-preview-title-content-style">人体浏览</div>
          </div>
          <div class="mark-no-layout">
            <form style="padding: 8px 0 0 25px;">
              <img src="../assets/img/biaozhu.png" alt="" width="22" style="position: relative; top: 4px">
              <span class="mark-no-content">标注号: </span>
              <input v-model="markNumKeyWord" class="search-MN"></input>
            </form>
            <div class="search-content-MN" v-if="hasNoDataShow">
              <ul>
                <li id="liClick" class="li-style" v-for="(item,key) in markNumList" @click="li_click(item)" :key="key">
                  {{ item }}: *对应的说明文字*
                </li>
                <li class="li-style" v-if="hasNoData">没有找到匹配数据</li>
              </ul>
            </div>
          </div>
          <div class="body-view-layout">
            <div class="body-part">
              <div>
                <div class="body-part-item" v-for="(item,key) in bodyItem" :style="bodyItemStyle[key]">
                  {{ item }}
                </div>
              </div>
              <div class="body-part-line"></div>
            </div>
            <div class="body-part-img">
              <div id="lineMove"
                   :style="'display: flex;justify-content: left;height: auto;position: absolute;top:'+positionMoveLine[moveLineIndex]+'px;'"
                   v-drag="{getIndex:getIndex}">
                <div class="scale-line"></div>
                <div class="scale-counter">
                  <button class="sub-icon" @click="px_sub"><img src="../assets/img/sub.png" alt=""></button>
                  <span id="scale_value">{{ markNum[moveLineIndex] }}</span>
                  <button class="plus-icon" @click="px_add"><img src="../assets/img/plus.png" alt=""></button>
                </div>
              </div>
            </div>
            <div class="body-part-description">
              <a class="body-part-description-content" href="" v-for="item in bodyPartDisc_">
                {{ item }}
              </a>
            </div>
          </div>
        </div>
        <!--          图片浏览区-->
        <div class="img-preview-container">
          <div class="img-title-layout">
            <div class="divider-3"></div>
            <div class="body-preview-title-content-style">图片浏览</div>
          </div>
          <div class="img-view-container">
            <el-button @click="px_sub" type="primary" class="upper-layout-button">
              <span class="content-style-ULB">上一层</span>
            </el-button>
            <div class="img-groups-name-layout">
              <div class="img-GN-content-layout"
                   v-for="(item,value) in imgGroupName"
                   :style="value===0?'margin-left: 8px':'margin-left: 5px'"><span class="style-GNC">{{ item }}</span>
              </div>
            </div>
            <div class="img-groups" v-for="i in [1,2,3]">
              <div style="display: flex;justify-content: center;">
                <router-link :to="{path:'/img_brow',query:{moveLineIndex:moveLineIndex,line_:i}}"
                             v-for="(img,key) in imgGroupsPath_[moveLineIndex+i-1]" :key="key"
                             class="img-G-content-layout">
                  <img :src="img" alt=""/>
                </router-link>
              </div>
              <div style="display: flex;justify-content: left">
                <div class="line-1-H"></div>
                <el-divider class="divider-4">
                  <div class="divider-4-content">
                    <span class="style-D4" :style="i===2?'color:red':''">
                      {{ displayLineLoc[i - 1] }}
                    </span>
                  </div>
                </el-divider>
                <div class="line-2-H"></div>
              </div>
            </div>
            <el-button @click="px_add" type="primary" class="upper-layout-button">
              <span class="content-style-ULB">下一层</span>
            </el-button>
          </div>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import {handleNodeClick} from '@/assets/js/home'

export default {
  name: "Home",
  components: {
    Header
  },
  methods: {
    li_click(item) {
      for (let i = 0; i < this.markNum.length; i++) {
        if (item == this.markNum[i]) {
          this.moveLineIndex = i;
          this.markNumKeyWord = this.markNum[i] + '：*对应的说明文字*'
          this.hasNoDataShow = false
        }
      }
    },
    // 根据鼠标移动的距离更新显示的下标
    getIndex(moving) {
      console.log("当前移动偏移量" + moving);
      let minIndex = 0;
      let tmp = 1000;
      for (let i = 0; i < this.positionMoveLine.length; i++) {
        let difference = Math.abs(parseInt(this.positionMoveLine[i]) - moving);
        if (difference <= tmp) {
          tmp = difference;
          minIndex = i;
        }
      }
      console.log("当前移动到" + this.positionMoveLine[minIndex]);
      this.moveLineIndex = minIndex;
      return this.positionMoveLine[minIndex];
    },

    // 点击菜单的node 更新界面数据
    handleNodeClick(data) {
      const that = this;
      that.bodyPartDisc_ = []
      handleNodeClick(data);
      if (data.id === '1425667094141358081') {
        this.axios.get('/jeecg-boot/human/humenOrgan/queryById', {
          params: {
            id: data.id
          }
        }).then(function (responses) {
          that.moveLineIndex = 4;
          that.bodyPartDisc_.push(responses.result.anatomyMap)
        }).catch(function (error) {
          console.log('请求失败-----' + error)
        })
      }
    },

    px_add() {
      if (this.moveLineIndex === this.markNum.length - 1) {
        this.moveLineIndex = 0;
      } else {
        this.moveLineIndex++;
      }
    },
    px_sub() {
      if (this.moveLineIndex === 0) {
        this.moveLineIndex = this.markNum.length - 1;
      } else {
        this.moveLineIndex--;
      }
    },
    getPicture(id) {
      this.axios.get('/jeecg-boot/human/humenOrgan/queryById', {
        params: {
          id: id
        }
      }).then(res => {
        console.log(res)
      })
    },
    humenOrganList(that) {
      // 页面渲染完成后获取一次
      this.axios.get('/jeecg-boot/human/humenOrgan/list'
      ).then(function (responses) {
        that.menu_data = responses.result
      }).catch(function (error) {
        console.log('请求失败-----' + error)
      })
    },
    pictureBrowsingList(that) {
      this.axios.get('/jeecg-boot/picture_browsing/pictureBrowsing/list'
      ).then(res => {
        that.pictureBrowsing.push(res.result.records)

      }).catch(err => {
        console.log('切面列表请求不成功')
      })
    }
  },
  data() {
    //首页左侧菜单栏数据
    var moveLineIndex = 0;
    // 人体浏览右侧的人体部位描述
    var imgGroupName = [
      '解剖图',
      'CT软组织窗',
      'CT骨窗',
      '组织切片'
    ];
    const bodyItemStyle = [
      'margin:10px 0 0 10px',
      'margin: 43px 0 0 10px',
      'margin: 48px 0 0 10px',
      'margin: 88px 0 0 10px',
      'margin: 54px 0 0 10px',
      'margin: 160px 0 0 10px',
    ];
    const bodyItem = [
      '头部',
      '颈部',
      '胸部',
      '腹部',
      '盆会阴',
      '下肢'
    ];
    // 列举了40个
    const markNum = [
      8, 17, 26, 56, 73, 86, 95, 100, 130, 140, 158, 168, 174, 198,
      246, 298, 320, 356, 418, 446, 456, 466, 478, 502, 568, 605,
      655, 708, 750, 880, 1060, 1121, 1300, 1370, 1400, 1414, 1440,
      1585, 1670, 1901
    ];
    const positionMoveLine = [
      '255', '262', '270', '278', '285', '295', '300', '303', '308', '315', '320', '325', '335',
      '350', '370', '390', '405', '432', '445', '450', '453', '456', '466', '476', '492', '515',
      '520', '540', '555', '575', '600', '625', '650p', '663', '670', '678', '680',
      '720', '760', '880'
    ];
    // 图片浏览中图片的路径
    var imgGroupsPath_ = [
      [require('@/assets/img/body_part_img/1901_1.png'), require('@/assets/img/body_part_img/1901_2.png'),
        require('@/assets/img/body_part_img/1901_3.png'), require('@/assets/img/body_part_img/1901_4.png')],
      [require('@/assets/img/body_part_img/8_1.png'), require('@/assets/img/body_part_img/8_2.png'),
        require('@/assets/img/body_part_img/8_3.png'), require('@/assets/img/body_part_img/8_4.png')],
      [require('@/assets/img/body_part_img/17_1.png'), require('@/assets/img/body_part_img/17_2.png'),
        require('@/assets/img/body_part_img/17_3.png'), require('@/assets/img/body_part_img/17_4.png')],
      [require('@/assets/img/body_part_img/26_1.png'), require('@/assets/img/body_part_img/26_2.png'),
        require('@/assets/img/body_part_img/26_3.png'), require('@/assets/img/body_part_img/26_4.png')],
      [require('@/assets/img/body_part_img/56_1.png'), require('@/assets/img/body_part_img/56_2.png'),
        require('@/assets/img/body_part_img/56_3.png'), require('@/assets/img/body_part_img/56_4.png')],
      [require('@/assets/img/body_part_img/73_1.png'), require('@/assets/img/body_part_img/73_2.png'),
        require('@/assets/img/body_part_img/73_3.png'), require('@/assets/img/body_part_img/73_4.png')],
      [require('@/assets/img/body_part_img/86_1.png'), require('@/assets/img/body_part_img/86_2.png'),
        require('@/assets/img/body_part_img/86_3.png'), require('@/assets/img/body_part_img/86_4.png')],
      [require('@/assets/img/body_part_img/95_1.png'), require('@/assets/img/body_part_img/95_2.png'),
        require('@/assets/img/body_part_img/95_3.png'), require('@/assets/img/body_part_img/95_4.png')],
      [require('@/assets/img/body_part_img/100_1.png'), require('@/assets/img/body_part_img/100_2.png'),
        require('@/assets/img/body_part_img/100_3.png'), require('@/assets/img/body_part_img/100_4.png')],
      [require('@/assets/img/body_part_img/130_1.png'), require('@/assets/img/body_part_img/130_2.png'),
        require('@/assets/img/body_part_img/130_3.png'), require('@/assets/img/body_part_img/130_4.png')],
      [require('@/assets/img/body_part_img/140_1.png'), require('@/assets/img/body_part_img/140_2.png'),
        require('@/assets/img/body_part_img/140_3.png'), require('@/assets/img/body_part_img/140_4.png')],
      [require('@/assets/img/body_part_img/158_1.png'), require('@/assets/img/body_part_img/158_2.png'),
        require('@/assets/img/body_part_img/158_3.png'), require('@/assets/img/body_part_img/158_4.png')],
      [require('@/assets/img/body_part_img/168_1.png'), require('@/assets/img/body_part_img/168_2.png'),
        require('@/assets/img/body_part_img/168_3.png'), require('@/assets/img/body_part_img/168_4.png')],
      [require('@/assets/img/body_part_img/174_1.png'), require('@/assets/img/body_part_img/174_2.png'),
        require('@/assets/img/body_part_img/174_3.png'), require('@/assets/img/body_part_img/174_4.png')],
      [require('@/assets/img/body_part_img/198_1.png'), require('@/assets/img/body_part_img/198_2.png'),
        require('@/assets/img/body_part_img/198_3.png'), require('@/assets/img/body_part_img/198_4.png')],
      [require('@/assets/img/body_part_img/246_1.png'), require('@/assets/img/body_part_img/246_2.png'),
        require('@/assets/img/body_part_img/246_3.png'), require('@/assets/img/body_part_img/246_4.png')],
      [require('@/assets/img/body_part_img/298_1.png'), require('@/assets/img/body_part_img/298_2.png'),
        require('@/assets/img/body_part_img/298_3.png'), require('@/assets/img/body_part_img/298_4.png')],
      [require('@/assets/img/body_part_img/320_1.png'), require('@/assets/img/body_part_img/320_2.png'),
        require('@/assets/img/body_part_img/320_3.png'), require('@/assets/img/body_part_img/320_4.png')],
      [require('@/assets/img/body_part_img/356_1.png'), require('@/assets/img/body_part_img/356_2.png'),
        require('@/assets/img/body_part_img/356_3.png'), require('@/assets/img/body_part_img/356_4.png')],
      [require('@/assets/img/body_part_img/418_1.png'), require('@/assets/img/body_part_img/418_2.png'),
        require('@/assets/img/body_part_img/418_3.png'), require('@/assets/img/body_part_img/418_4.png')],
      [require('@/assets/img/body_part_img/446_1.png'), require('@/assets/img/body_part_img/446_2.png'),
        require('@/assets/img/body_part_img/446_3.png'), require('@/assets/img/body_part_img/446_4.png')],
      [require('@/assets/img/body_part_img/456_1.png'), require('@/assets/img/body_part_img/456_2.png'),
        require('@/assets/img/body_part_img/456_3.png'), require('@/assets/img/body_part_img/456_4.png')],
      [require('@/assets/img/body_part_img/466_1.png'), require('@/assets/img/body_part_img/466_2.png'),
        require('@/assets/img/body_part_img/466_3.png'), require('@/assets/img/body_part_img/466_4.png')],
      [require('@/assets/img/body_part_img/478_1.png'), require('@/assets/img/body_part_img/478_2.png'),
        require('@/assets/img/body_part_img/478_3.png'), require('@/assets/img/body_part_img/478_4.png')],
      [require('@/assets/img/body_part_img/502_1.png'), require('@/assets/img/body_part_img/502_2.png'),
        require('@/assets/img/body_part_img/502_3.png'), require('@/assets/img/body_part_img/502_4.png')],
      [require('@/assets/img/body_part_img/568_1.png'), require('@/assets/img/body_part_img/568_2.png'),
        require('@/assets/img/body_part_img/568_3.png'), require('@/assets/img/body_part_img/568_4.png')],
      [require('@/assets/img/body_part_img/605_1.png'), require('@/assets/img/body_part_img/605_2.png'),
        require('@/assets/img/body_part_img/605_3.png'), require('@/assets/img/body_part_img/605_4.png')],
      [require('@/assets/img/body_part_img/655_1.png'), require('@/assets/img/body_part_img/655_2.png'),
        require('@/assets/img/body_part_img/655_3.png'), require('@/assets/img/body_part_img/655_4.png')],
      [require('@/assets/img/body_part_img/708_1.png'), require('@/assets/img/body_part_img/708_2.png'),
        require('@/assets/img/body_part_img/708_3.png'), require('@/assets/img/body_part_img/708_4.png')],
      [require('@/assets/img/body_part_img/750_1.png'), require('@/assets/img/body_part_img/750_2.png'),
        require('@/assets/img/body_part_img/750_3.png'), require('@/assets/img/body_part_img/750_4.png')],
      [require('@/assets/img/body_part_img/880_1.png'), require('@/assets/img/body_part_img/880_2.png'),
        require('@/assets/img/body_part_img/880_3.png'), require('@/assets/img/body_part_img/880_4.png')],
      [require('@/assets/img/body_part_img/1060_1.png'), require('@/assets/img/body_part_img/1060_2.png'),
        require('@/assets/img/body_part_img/1060_3.png'), require('@/assets/img/body_part_img/1060_4.png')],
      [require('@/assets/img/body_part_img/1121_1.png'), require('@/assets/img/body_part_img/1121_2.png'),
        require('@/assets/img/body_part_img/1121_3.png'), require('@/assets/img/body_part_img/1121_4.png')],
      [require('@/assets/img/body_part_img/1300_1.png'), require('@/assets/img/body_part_img/1300_2.png'),
        require('@/assets/img/body_part_img/1300_3.png'), require('@/assets/img/body_part_img/1300_4.png')],
      [require('@/assets/img/body_part_img/1370_1.png'), require('@/assets/img/body_part_img/1370_2.png'),
        require('@/assets/img/body_part_img/1370_3.png'), require('@/assets/img/body_part_img/1370_4.png')],
      [require('@/assets/img/body_part_img/1400_1.png'), require('@/assets/img/body_part_img/1400_2.png'),
        require('@/assets/img/body_part_img/1400_3.png'), require('@/assets/img/body_part_img/1400_4.png')],
      [require('@/assets/img/body_part_img/1414_1.png'), require('@/assets/img/body_part_img/1414_2.png'),
        require('@/assets/img/body_part_img/1414_3.png'), require('@/assets/img/body_part_img/1414_4.png')],
      [require('@/assets/img/body_part_img/1440_1.png'), require('@/assets/img/body_part_img/1440_2.png'),
        require('@/assets/img/body_part_img/1440_3.png'), require('@/assets/img/body_part_img/1440_4.png')],
      [require('@/assets/img/body_part_img/1585_1.png'), require('@/assets/img/body_part_img/1585_2.png'),
        require('@/assets/img/body_part_img/1585_3.png'), require('@/assets/img/body_part_img/1585_4.png')],
      [require('@/assets/img/body_part_img/1670_1.png'), require('@/assets/img/body_part_img/1670_2.png'),
        require('@/assets/img/body_part_img/1670_3.png'), require('@/assets/img/body_part_img/1670_4.png')],
      [require('@/assets/img/body_part_img/1901_1.png'), require('@/assets/img/body_part_img/1901_2.png'),
        require('@/assets/img/body_part_img/1901_3.png'), require('@/assets/img/body_part_img/1901_4.png')],
      [require('@/assets/img/body_part_img/8_1.png'), require('@/assets/img/body_part_img/8_2.png'),
        require('@/assets/img/body_part_img/8_3.png'), require('@/assets/img/body_part_img/8_4.png')],
    ];
    var displayLineLoc = [1901, 8, 17];
    let menu_data;
    let pictureBrowsing = [];
    let bodyPartDisc_ = [];
    return {
      hasNoDataShow: false,
      bodyPartDisc_,
      markNumList: [],
      markNumKeyWord: '',
      displayLineLoc,
      pictureBrowsing,
      imgGroupsPath_,
      moveLineIndex,
      positionMoveLine,
      markNum,
      imgGroupName,
      menu_data,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      bodyItemStyle,
      bodyItem,
    };
  },
  // 自定义指令
  directives: {
    drag: {
      // 指令的定义
      inserted(el, binding) {
        // 获取元素
        // 只上下移动，因此只要操纵顶部的偏移量即刻
        let y = 0;
        let t = 0;
        let isDown = false;
        //  鼠标按下事件
        el.onmousedown = function (e) {
          //  获取y坐标
          y = e.clientY;
          //  获取组件左部和顶部的偏移量
          t = el.offsetTop;
          //  开关打开
          isDown = true;
          //  设置样式
          el.style.cursor = 'move';
        };
        //  鼠标移动
        window.onmousemove = function (e) {
          if (isDown === false)
            return;
          //  获取移动x,y
          let my = e.clientY;
          //    计算移动后的左偏移量和顶部偏移量
          let mt = my - (y - t);
          el.style.top = mt + 'px';
        };
        //  鼠标抬起事件
        el.onmouseup = function (e) {
          let uy = e.clientY;
          let ut = uy - (y - t);
          // 判断当前移动距离更接近哪个阈值，同时更新moveLineIndex
          let positionML = binding.value.getIndex(ut);
          el.style.top = positionML + 'px';
          isDown = false;
          el.style.cursor = "default";
        }
      }
    }
  },
  watch: {
    // moveLineIndex 根据moveLineIndex请求后台数据
    moveLineIndex: {
      handler: function () {
        if (this.moveLineIndex === 0) {
          this.displayLineLoc[0] = this.markNum[this.markNum.length - 1];
          this.displayLineLoc[1] = this.markNum[0];
          this.displayLineLoc[2] = this.markNum[1];
        } else if (this.moveLineIndex === this.markNum.length - 1) {
          this.displayLineLoc[0] = this.markNum[this.markNum.length - 2];
          this.displayLineLoc[1] = this.markNum[this.markNum.length - 1];
          this.displayLineLoc[2] = this.markNum[0];
        } else {
          this.displayLineLoc[0] = this.markNum[this.moveLineIndex - 1];
          this.displayLineLoc[1] = this.markNum[this.moveLineIndex];
          this.displayLineLoc[2] = this.markNum[this.moveLineIndex + 1];
        }
      },
      deep: true
    },
    markNumKeyWord() {
      if (!this.markNumKeyWord) {
        this.markNumKeyWord = []
        return
      }
      this.hasNoDataShow = this.markNumKeyWord.length < 10;
      /*
      * 将搜索匹配的结果添加进markNumList[]中:
      * (markNumKeyWord：数字或者中文，从markNum[](标注号)，以及markNumContent[](每个标注号对应的内容)数组中匹配)
      * 1、判断markNum中是否包含markNumKeyWord
      * 2、获取所有包含的下标，将两个数组的内容push到markNumList[]中
      * */
      console.log(this.markNumKeyWord);
      const that = this;
      this.markNumList = []
      this.markNum.forEach(function (value, index, array) {
        //includes()不支持IE浏览器
        let isInclude = value.toString().includes(that.markNumKeyWord);
        if (isInclude === true) {
          that.markNumList.push(value);
          console.log(value);
          console.log(that.markNumList);
        }
      })
    },
  },
  computed: {
    hasNoData() {
      return !this.markNumList.length
    }
  },
  mounted() {
    const that = this;
    // 页面渲染完后刷新页面左侧菜单列表
    this.humenOrganList(that);
    // 加载默认顺序浏览图片
    this.pictureBrowsingList(that);
  }
}
</script>

<style scoped>
/deep/.el-tree-node>.el-tree-node__children{
  /*overflow: auto;*/
}
</style>
