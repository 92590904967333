<template>
  <div class="header">
        <img src="../assets/img/login/logo.png" class="logo-H" height="85" alt="">
  </div>
</template>

<script>
export default {
  name: "loginBG",
  components: {}
}
</script>

<style scoped>
.header{
  width: 1300px;
  margin:0 auto;
}
</style>
