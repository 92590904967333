// 引入
import Vue from 'vue'
import Router from 'vue-router'

// 注册
Vue.use(Router)

export default new Router({
    mode: 'history',//去掉#号
    routes: [
        {
            path: '/home',
            name: 'home',
            component: () => import('../view/home'),
        },
        {
            path: '/',
            name: 'login',
            component: () => import('../view/Login'),
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('../view/Register'),
        },
        {
            path: '/forgot_password',
            name: 'forgot_password',
            component: () => import('../view/ForgotPwd'),
        },
        {
            path: '/img_brow',
            name: 'img_brow',
            component: () => import('../view/ImgBrow')
        },
        {
            path: '/answer',
            name: 'answer',
            component: () => import('../view/Answer')
        },
        {
            path: '/examPaperList',
            name: 'ExamPaperList',
            component: () => import('../view/ExamPaperList')
        },
        {
            path: '/examHistoryList',
            name: 'ExamHistoryList',
            component: () => import('../view/ExamHistoryList')
        },
        {
            path: '/personal_center',
            name: 'personal_center',
            component: () => import('../view/PersonalCenter'),
            children: [
                {
                    path: '/',
                    name: 'login_history',
                    component: () => import('../components/LoginHistory'),
                },
                {
                    path: '/my_test',
                    name: 'my_test',
                    component: () => import('../components/MyTest'),
                },
                {
                    path: '/exam_database',
                    name: 'exam_database',
                    component: () => import('../components/ExamDB'),
                },
            ]
        }
    ]
})
