<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Home from './view/home';
import ImgBrow from "@/view/ImgBrow";
import Answer from "@/view/Answer";
import loginBG from "@/components/loginBG";

export default {
  name: 'App',
  title:'协和医院',
  components: {
    Home,
    ImgBrow,
    Answer,
    loginBG
  }
}
</script>

<style>
@import "assets/css/style_IB.css";
@import "assets/css/home_center.css";
@import "assets/css/home_left_aside.css";
@import "assets/css/style_L.css";
@import "assets/css/style-A.css";
@import "assets/css/style_PL.css";

body {
  margin: 0;
  /*禁止内容被选中*/
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
#app{
  display: grid;
}
div::-webkit-scrollbar {
  display: none;
}

input {
  -webkit-user-select: auto; /*webkit浏览器*/
}

textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}
</style>
