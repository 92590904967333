<template>
  <div class="header-container">
    <div class="head-wrap">
      <div class="logo-header-layout" @click="handleToPage('/home')">
        <img src="../assets/img/logo.png" class="logo-H" height="83">
      </div>

      <div class="divider-2"></div>
      <div class="name-header-layout">
        <p class="name-header-style">中国数字化可视人体数据库</p>
      </div>
      <div class="info-header-layout">
        <router-link :to="{path:'/examPaperList'}" class="action-item">
          <img src="../assets/img/dati.png" style="position: relative;top: 3px">
          <span class="action-content">仿真答题</span>
        </router-link>
        <!--        <router-link class="action-item" style="margin-left: 28px" :to="{path:'/personal_center',query:{isPSShow: true}}">-->
        <!--          <i class="el-icon-user" style="position: relative;top: 2px;color: #FFFFFF"/>-->
        <!--          <span class="action-content">个人中心</span>-->
        <!--        </router-link>-->
<!--        <a class="action-item"><span class="action-content">{{ userName }}</span></a>-->

        <router-link class="action-item" :to="{path:'/'}">
          <img src="../assets/img/tuichu.png" style="position: relative;top: 2px">
          <span class="action-content">退出</span>
        </router-link>
        <!--      <div class="info-content-header" v-if="personalInfo">-->
        <!--        <p>性别：{{ gender }} / 年龄：{{ age }} / 身高：{{ height }}cm / 体重：{{ weight }}kg</p>-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import tu1_1 from "@/assets/img/tu1.png";
import tu1_2 from "@/assets/img/tu1_2.png";
import tu1_3 from "@/assets/img/tu1_3.png";
import tu1_4 from "@/assets/img/tu1_4.png";

export default {
  name: "Header",
  props: ['personalInfo'],
  data() {
    return {
      userName: undefined
    };
  },
  methods: {
    handleToPage(path) {
      this.$router.push(path)
    }
  },
  mounted() {
    this.userName = sessionStorage.getItem('userName')
    // 个人中心界面格式
    if (this.personalInfo === false) {
      let elem = document.getElementsByClassName('action-item');
      for (let i = 0; i < elem.length; i++) {
        elem[i].style.paddingTop = "18px";
      }
    }
  },
}
</script>

<style scoped>
.head-wrap {
  width: 1050px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.header-container {
  height: 90px;
  background-color: #089BAB;
  margin: 0 auto;

  padding-right: 20px;
}

</style>
