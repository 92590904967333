<template>
  <div class="main-container-IB">
    <Header :personalInfo="true"></Header>
    <div class="main-container-IB-2">
      <div class="left-contain-IB">
        <el-header class="img-title-layout" style="padding-top: 35px">
          <div class="divider-3"></div>
          <div class="body-preview-title-content-style">图片浏览</div>
        </el-header>

        <!--      图片预览-->
        <div class="img-preview-container-IB">
          <div style="text-align: center">
            <el-button @click="px_sub" type="primary" class="upper-layout-button">
              <span class="content-style-ULB">上一层</span>
            </el-button>
            <div class="img-groups-name-layout">
              <div
                  class="img-GN-content-layout"
                  style="width: 115px"
                  v-for="(item,value) in imgGroupName"
                  :style="value===0?'margin-left: 13px':'margin-left: 11px'">
                <span class="style-GNC">{{ item }}</span>
              </div>
            </div>
            <div class="img-groups" v-for="i in [1,2,3]">
              <div style="display: flex;justify-content: center;">
                <a class="img-G-content-layout" v-for="(img,key) in imgGroupsPath_[moveLineIndex_+i-1]" :key="key">
                  <img :src="img" class="style-IGC" alt=""/>
                </a>
              </div>
              <div style="display: flex;justify-content: left">
                <div class="line-1"></div>
                <el-divider class="divider-4">
                  <div class="divider-4-content">
                    <span class="style-D4" :style="i===2?'color:red':''">
                      {{ displayLineLoc[i - 1] }}
                    </span>
                  </div>
                </el-divider>
                <div class="line-2"></div>
              </div>
            </div>
            <el-button type="primary" @click="px_add" class="upper-layout-button">
              <span class="content-style-ULB">下一层</span>
            </el-button>
          </div>
        </div>
      </div>
      <el-main class="center-container-IB">
        <div style="width:100%; display: flex; justify-content: left;">
          <div style="width: 300px" v-for="i in [0,1]">
            <img :src="imgTypeIcon[i]" style="position: relative;top: 5px" alt="">
            <span class="content-M-IB">{{ imgType[i] }}</span>
          </div>
        </div>
        <div class="img-view-center-IB">
          <div class="img-action-IVC-IB">
            <div class="sub-plus-action-layout-IVC">
              <div class="sub-plus-action-IVC">
                <img src="../assets/img/plus.png" style="position: relative;top: 10px"><br>
                <img src="../assets/img/sub_2.png" style="position: relative;top: 20px">
                <!-- <a style="position: relative;top: 10px; color: #089BAB;font-size: 28px">+</a><br>-->
                <!-- <a  style="position: relative;top: 10px; color: #089BAB;font-size: 38px">-</a>-->
              </div>
            </div>
            <div class="institu-name-IVC">
              <span class="style-institu-name-IVC">Third Military Medical University</span>
            </div>
          </div>
          <div class="img-show-IVC"><img src="../assets/img/tu2.png" width="70%"></div>
          <div class="img-show-info-IVC">第3胸椎锥体层面</div>
        </div>
        <div style="height: 100px;display: flex;flex-wrap: wrap; margin-top: 20px">
          <div class="infoImg-layout-IVC" v-for="i in infoImg[0]">
            <span class="style-infoImg-IVC">{{ i }}</span>
          </div>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import icon1 from "@/assets/img/icon1.png";
import icon2 from "@/assets/img/icon2.png";
import Header from "@/components/Header";

export default {
  name: "ImgBrow",
  components: {
    Header
  },
  methods: {
    px_add() {
      if (this.moveLineIndex_ === this.markNum.length - 1) {
        this.moveLineIndex_ = 0;
      } else {
        this.moveLineIndex_++;
      }
    },
    px_sub() {
      if (this.moveLineIndex_ === 0) {
        this.moveLineIndex_ = this.markNum.length - 1;
      } else {
        this.moveLineIndex_--;
      }
    }
  },
  data() {
    var line_ = 1;
    var moveLineIndex_ = 0;
    const imgType = [
      '注释词汇对应有人体解剖图',
      '注释词汇对应有组织切片图'
    ];
    const imgTypeIcon = [
      icon1,
      icon2
    ];
    const imgGroupName = [
      '解剖图',
      'CT软组织窗',
      'CT骨窗',
      '组织切片'
    ];
    const infoImg = [
      [
        'Brain (脑)',
        'Left parietal bone (左顶骨)',
        'Occipital bone (枕骨)',
        'Right parietal bone (右顶骨)',
        'Superficial fascia (浅筋膜)',
        'Superior sagittal sinus (上矢状窦)',
        'Acromial part of left deltoid (--)',
        'Acromial part of right deltoid (--)',
        'Bony part of right inferior articular process of third thoracic vertebra (--)'
      ]
    ];
    // 列举了40个
    const markNum = [
      8, 17, 26, 56, 73, 86, 95, 100, 130, 140, 158, 168, 174, 198,
      246, 298, 320, 356, 418, 446, 456, 466, 478, 502, 568, 605,
      655, 708, 750, 880, 1060, 1121, 1300, 1370, 1400, 1414, 1440,
      1585, 1670, 1901
    ];
    // 图片浏览中图片的路径
    var imgGroupsPath_ = [
      [require('@/assets/img/body_part_img/1901_1.png'), require('@/assets/img/body_part_img/1901_2.png'),
        require('@/assets/img/body_part_img/1901_3.png'), require('@/assets/img/body_part_img/1901_4.png')],
      [require('@/assets/img/body_part_img/8_1.png'), require('@/assets/img/body_part_img/8_2.png'),
        require('@/assets/img/body_part_img/8_3.png'), require('@/assets/img/body_part_img/8_4.png')],
      [require('@/assets/img/body_part_img/17_1.png'), require('@/assets/img/body_part_img/17_2.png'),
        require('@/assets/img/body_part_img/17_3.png'), require('@/assets/img/body_part_img/17_4.png')],
      [require('@/assets/img/body_part_img/26_1.png'), require('@/assets/img/body_part_img/26_2.png'),
        require('@/assets/img/body_part_img/26_3.png'), require('@/assets/img/body_part_img/26_4.png')],
      [require('@/assets/img/body_part_img/56_1.png'), require('@/assets/img/body_part_img/56_2.png'),
        require('@/assets/img/body_part_img/56_3.png'), require('@/assets/img/body_part_img/56_4.png')],
      [require('@/assets/img/body_part_img/73_1.png'), require('@/assets/img/body_part_img/73_2.png'),
        require('@/assets/img/body_part_img/73_3.png'), require('@/assets/img/body_part_img/73_4.png')],
      [require('@/assets/img/body_part_img/86_1.png'), require('@/assets/img/body_part_img/86_2.png'),
        require('@/assets/img/body_part_img/86_3.png'), require('@/assets/img/body_part_img/86_4.png')],
      [require('@/assets/img/body_part_img/95_1.png'), require('@/assets/img/body_part_img/95_2.png'),
        require('@/assets/img/body_part_img/95_3.png'), require('@/assets/img/body_part_img/95_4.png')],
      [require('@/assets/img/body_part_img/100_1.png'), require('@/assets/img/body_part_img/100_2.png'),
        require('@/assets/img/body_part_img/100_3.png'), require('@/assets/img/body_part_img/100_4.png')],
      [require('@/assets/img/body_part_img/130_1.png'), require('@/assets/img/body_part_img/130_2.png'),
        require('@/assets/img/body_part_img/130_3.png'), require('@/assets/img/body_part_img/130_4.png')],
      [require('@/assets/img/body_part_img/140_1.png'), require('@/assets/img/body_part_img/140_2.png'),
        require('@/assets/img/body_part_img/140_3.png'), require('@/assets/img/body_part_img/140_4.png')],
      [require('@/assets/img/body_part_img/158_1.png'), require('@/assets/img/body_part_img/158_2.png'),
        require('@/assets/img/body_part_img/158_3.png'), require('@/assets/img/body_part_img/158_4.png')],
      [require('@/assets/img/body_part_img/168_1.png'), require('@/assets/img/body_part_img/168_2.png'),
        require('@/assets/img/body_part_img/168_3.png'), require('@/assets/img/body_part_img/168_4.png')],
      [require('@/assets/img/body_part_img/174_1.png'), require('@/assets/img/body_part_img/174_2.png'),
        require('@/assets/img/body_part_img/174_3.png'), require('@/assets/img/body_part_img/174_4.png')],
      [require('@/assets/img/body_part_img/198_1.png'), require('@/assets/img/body_part_img/198_2.png'),
        require('@/assets/img/body_part_img/198_3.png'), require('@/assets/img/body_part_img/198_4.png')],
      [require('@/assets/img/body_part_img/246_1.png'), require('@/assets/img/body_part_img/246_2.png'),
        require('@/assets/img/body_part_img/246_3.png'), require('@/assets/img/body_part_img/246_4.png')],
      [require('@/assets/img/body_part_img/298_1.png'), require('@/assets/img/body_part_img/298_2.png'),
        require('@/assets/img/body_part_img/298_3.png'), require('@/assets/img/body_part_img/298_4.png')],
      [require('@/assets/img/body_part_img/320_1.png'), require('@/assets/img/body_part_img/320_2.png'),
        require('@/assets/img/body_part_img/320_3.png'), require('@/assets/img/body_part_img/320_4.png')],
      [require('@/assets/img/body_part_img/356_1.png'), require('@/assets/img/body_part_img/356_2.png'),
        require('@/assets/img/body_part_img/356_3.png'), require('@/assets/img/body_part_img/356_4.png')],
      [require('@/assets/img/body_part_img/418_1.png'), require('@/assets/img/body_part_img/418_2.png'),
        require('@/assets/img/body_part_img/418_3.png'), require('@/assets/img/body_part_img/418_4.png')],
      [require('@/assets/img/body_part_img/446_1.png'), require('@/assets/img/body_part_img/446_2.png'),
        require('@/assets/img/body_part_img/446_3.png'), require('@/assets/img/body_part_img/446_4.png')],
      [require('@/assets/img/body_part_img/456_1.png'), require('@/assets/img/body_part_img/456_2.png'),
        require('@/assets/img/body_part_img/456_3.png'), require('@/assets/img/body_part_img/456_4.png')],
      [require('@/assets/img/body_part_img/466_1.png'), require('@/assets/img/body_part_img/466_2.png'),
        require('@/assets/img/body_part_img/466_3.png'), require('@/assets/img/body_part_img/466_4.png')],
      [require('@/assets/img/body_part_img/478_1.png'), require('@/assets/img/body_part_img/478_2.png'),
        require('@/assets/img/body_part_img/478_3.png'), require('@/assets/img/body_part_img/478_4.png')],
      [require('@/assets/img/body_part_img/502_1.png'), require('@/assets/img/body_part_img/502_2.png'),
        require('@/assets/img/body_part_img/502_3.png'), require('@/assets/img/body_part_img/502_4.png')],
      [require('@/assets/img/body_part_img/568_1.png'), require('@/assets/img/body_part_img/568_2.png'),
        require('@/assets/img/body_part_img/568_3.png'), require('@/assets/img/body_part_img/568_4.png')],
      [require('@/assets/img/body_part_img/605_1.png'), require('@/assets/img/body_part_img/605_2.png'),
        require('@/assets/img/body_part_img/605_3.png'), require('@/assets/img/body_part_img/605_4.png')],
      [require('@/assets/img/body_part_img/655_1.png'), require('@/assets/img/body_part_img/655_2.png'),
        require('@/assets/img/body_part_img/655_3.png'), require('@/assets/img/body_part_img/655_4.png')],
      [require('@/assets/img/body_part_img/708_1.png'), require('@/assets/img/body_part_img/708_2.png'),
        require('@/assets/img/body_part_img/708_3.png'), require('@/assets/img/body_part_img/708_4.png')],
      [require('@/assets/img/body_part_img/750_1.png'), require('@/assets/img/body_part_img/750_2.png'),
        require('@/assets/img/body_part_img/750_3.png'), require('@/assets/img/body_part_img/750_4.png')],
      [require('@/assets/img/body_part_img/880_1.png'), require('@/assets/img/body_part_img/880_2.png'),
        require('@/assets/img/body_part_img/880_3.png'), require('@/assets/img/body_part_img/880_4.png')],
      [require('@/assets/img/body_part_img/1060_1.png'), require('@/assets/img/body_part_img/1060_2.png'),
        require('@/assets/img/body_part_img/1060_3.png'), require('@/assets/img/body_part_img/1060_4.png')],
      [require('@/assets/img/body_part_img/1121_1.png'), require('@/assets/img/body_part_img/1121_2.png'),
        require('@/assets/img/body_part_img/1121_3.png'), require('@/assets/img/body_part_img/1121_4.png')],
      [require('@/assets/img/body_part_img/1300_1.png'), require('@/assets/img/body_part_img/1300_2.png'),
        require('@/assets/img/body_part_img/1300_3.png'), require('@/assets/img/body_part_img/1300_4.png')],
      [require('@/assets/img/body_part_img/1370_1.png'), require('@/assets/img/body_part_img/1370_2.png'),
        require('@/assets/img/body_part_img/1370_3.png'), require('@/assets/img/body_part_img/1370_4.png')],
      [require('@/assets/img/body_part_img/1400_1.png'), require('@/assets/img/body_part_img/1400_2.png'),
        require('@/assets/img/body_part_img/1400_3.png'), require('@/assets/img/body_part_img/1400_4.png')],
      [require('@/assets/img/body_part_img/1414_1.png'), require('@/assets/img/body_part_img/1414_2.png'),
        require('@/assets/img/body_part_img/1414_3.png'), require('@/assets/img/body_part_img/1414_4.png')],
      [require('@/assets/img/body_part_img/1440_1.png'), require('@/assets/img/body_part_img/1440_2.png'),
        require('@/assets/img/body_part_img/1440_3.png'), require('@/assets/img/body_part_img/1440_4.png')],
      [require('@/assets/img/body_part_img/1585_1.png'), require('@/assets/img/body_part_img/1585_2.png'),
        require('@/assets/img/body_part_img/1585_3.png'), require('@/assets/img/body_part_img/1585_4.png')],
      [require('@/assets/img/body_part_img/1670_1.png'), require('@/assets/img/body_part_img/1670_2.png'),
        require('@/assets/img/body_part_img/1670_3.png'), require('@/assets/img/body_part_img/1670_4.png')],
      [require('@/assets/img/body_part_img/1901_1.png'), require('@/assets/img/body_part_img/1901_2.png'),
        require('@/assets/img/body_part_img/1901_3.png'), require('@/assets/img/body_part_img/1901_4.png')],
      [require('@/assets/img/body_part_img/8_1.png'), require('@/assets/img/body_part_img/8_2.png'),
        require('@/assets/img/body_part_img/8_3.png'), require('@/assets/img/body_part_img/8_4.png')],
    ];
    var displayLineLoc = [1901, 8, 17];
    return {
      moveLineIndex_,
      markNum,
      imgGroupsPath_,
      displayLineLoc,
      imgGroupName,
      infoImg,
      imgType,
      imgTypeIcon,
    }
  },
  watch: {
    moveLineIndex_: {
      handler: function () {
        if (this.moveLineIndex_ === 0) {
          this.displayLineLoc[0] = this.markNum[this.markNum.length - 1];
          this.displayLineLoc[1] = this.markNum[0];
          this.displayLineLoc[2] = this.markNum[1];
        } else if (this.moveLineIndex_ === this.markNum.length - 1) {
          this.displayLineLoc[0] = this.markNum[this.markNum.length - 2];
          this.displayLineLoc[1] = this.markNum[this.markNum.length - 1];
          this.displayLineLoc[2] = this.markNum[0];
        } else {
          this.displayLineLoc[0] = this.markNum[this.moveLineIndex_ - 1];
          this.displayLineLoc[1] = this.markNum[this.moveLineIndex_];
          this.displayLineLoc[2] = this.markNum[this.moveLineIndex_ + 1];
        }
      },
      deep: true
    }
  },
  mounted() {
    // 路由跳转，参数初始化，加载相应图片---start
    this.line_ = this.$route.query.line_;
    this.line_++;
    this.line_--;
    this.moveLineIndex_ = this.$route.query.moveLineIndex;
    this.moveLineIndex_--;
    this.moveLineIndex_++;
    if (this.line_ === 1) {
      if (this.moveLineIndex_ === 0) this.moveLineIndex_ = this.markNum.length - 1;
      else this.moveLineIndex_--;
    } else if (this.line_ === 3) {
      if (this.moveLineIndex_ === this.markNum.length - 1) this.moveLineIndex_ = 0;
      else this.moveLineIndex_++;
    }
    // 路由跳转，参数初始化，加载相应图片----end
  }
}
</script>

<style>
/*@import "../assets/css/style_IB.css";*/
</style>
