import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'babel-polyfill'
// 使用路由
import VueRouter from 'vue-router'

Vue.prototype.axios = axios

Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.use(ElementUI)

// http request 拦截器
axios.interceptors.request.use(request => {
        console.log('request=', request)
        let whiteList = [
            '/jeecg-boot/sys/randomImage/',
            '/jeecg-boot/ns/authorize/access_token',
            '/jeecg-boot/user/login',
            '/jeecg-boot/humen_register/humenRegister/sendSms',
            '/jeecg-boot/humen_register/humenRegister/add',
            '/jeecg-boot/user/register'
        ]
        return new Promise(function (resolve, reject) {
            let token = sessionStorage.getItem('token')
            // console.log('token=', token)
            // console.log('request.url=', request.url)
            if (whiteList.some(list => request.url.indexOf(list) > -1) || token) {
                resolve(token);
            } else {
                console.log('您还没有登录')
                reject('您还没有登录');
            }
        }).then(function (token) {
            if (token) request.headers['X-Access-Token'] = token
            return request;
        }, function (error) {
            console.error(error)
            window.location.href = '/'
            return Promise.reject(error)
        })
    },
    error => {
        return Promise.reject(error)
    }
)
// http response 拦截器
axios.interceptors.response.use(response => {
    let res = response.data
    console.log('main res=', res)
    if (res.result && res.result.hasOwnProperty('status') && res.status === "1") {
        //token超时
        sessionStorage.removeItem('token')
    }
    return response.data

}, error => {
    window.location.href = '/'
    return Promise.reject(error);
})


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
