<template>
  <div class="main-container-A">
    <Header :personalInfo="true"></Header>
    <div class="main-container">
      <div v-for="item in examQuestionList" class="bottomBorder">
        <p class="font16 black666 bold" :id="item.topic.id"> {{ item.topic.topicName }} </p>
        <div v-for="topic in item.typeVoList">
          <p class="title-block-style">{{ topic.typeName }}</p>
          <div class="text-align" v-if="topic.organImages">
            <img :src="topic.organImages" width="300" style="margin-top: 10px" alt=" ">
            <el-image class="button-style" :src="url" :preview-src-list="[topic.organImages]"></el-image>
          </div>

          <div class="marginTop25" v-for="(questionVoList,idx) in topic.questionDetailsVoList"
               v-if="questionVoList.questionType===2">
            <div class="content-block-style">{{ idx + 1 }}、{{ questionVoList.questionTitle }}</div>

            <div class="marginTop15">
              <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="questionVoList.userAnswer">
              </el-input>
              <el-tag type="info" style="margin: 0 0 0px 10px" @click="submitTextarea(questionVoList,item.id)">提交
              </el-tag>
            </div>
            <div class="title-block-style marginTop5 orange" v-if="questionVoList.updateTime">
              答案：{{ questionVoList.questionAnswer }}
            </div>
          </div>
          <div v-for="(questionVoList,idx) in topic.questionVoList" v-if="questionVoList.questionType===1" class="marginTop15">
            <div class="content-block-style">{{ idx + 1 }}、{{ questionVoList.questionTitle }}</div>
            <div class="marginTop15">
              <el-radio-group v-model="questionVoList.userAnswer" id="radio_g"
                              @change="submitTextarea(questionVoList,item.id)"
                              style="margin-bottom:20px">
                <el-radio class="content-block-style"
                          style="font-size: 15px"
                          v-for="(j,index) in questionVoList.questionAnswerList.split(';')"
                          :label="radioLabel[index]"
                          :key="index"
                >{{ j }}
                </el-radio>
              </el-radio-group>
            </div>
            <div class="title-block-style marginTop5" v-if="questionVoList.updateTime">
              答案：{{ questionVoList.questionAnswer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import icon4_ok from "@/assets/img/icon4_ok.png"
import icon4_ok2 from "@/assets/img/icon4.png"
import enlargeImg from '@/assets/img/enlarge_img.png'

export default {
  name: "Answer",
  components: {
    Header,
  },
  data() {
    const radioLabel = ['A', 'B', 'C', 'D'];
    return {
      radioLabel,
      url: enlargeImg,
      examQuestionList: [],
      examList: [],
      submitAnswer: {
        questionId: '',
      }
    }
  },
  methods: {
    getAnswerContent(examId) {
      this.axios.get('/jeecg-boot/humanUserAnswer/answerDetailsById/' + examId).then(res => {
        this.examQuestionList = res.result
      })
    },
    submitTextarea(question, examId) {
      this.submitAnswer.examId = examId
      this.submitAnswer.questionId = question.questionId
      this.submitAnswer.userAnswer = question.userAnswer
      this.axios.post('/jeecg-boot/humanUserAnswer/add', this.submitAnswer).then(res => {
        if (res.success) {
          this.getAnswerContent(this.$route.query.id)
        }
      })
    }
  },
  mounted() {
    let token = this.$route.query.token
    if(token){
      sessionStorage.setItem('token',token)
    }
    this.getAnswerContent(this.$route.query.id)
  }
}
</script>


<style scoped>
.marginTop25 {
  margin-top: 25px;
}
.marginTop15 {
  margin-top: 15px;
}
.marginTop5{
  margin-top: 5px;
}
.width50 {
  width: 50%;
}

.text-align {
  text-align: center;
}

.width80 {
  width: 80%;
}

.black666 {
  color: #666;
}

.black888 {
  color: #888;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.main-container-A {
  background: #f1f1f1;
}

.bottomBorder {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 4px solid #f1f1f1;
}

.main-container {
  width: 1000px;
  margin: 15px auto;
  padding: 20px 30px;
  /*background: #EBF3F4;*/
  background: #fff;
  border-radius: 1px;
}
</style>
